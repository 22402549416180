import { useEffect } from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import { loadCaptchaEnginge, LoadCanvasTemplate, validateCaptcha } from 'react-simple-captcha'
function Contact() {
  useEffect(() => {
    loadCaptchaEnginge(6)
  }, [])
  return (
    <>
      <Header></Header>
      <section className="subbanner bg-blue d-flex align-items-center justify-content-center">
        <h1 className="text-white text-center">Contact</h1>
      </section>
      <footer className="">
        <div className="container container container-lg footer-container rounded-4 p-5">
          <div className="row">
            <div className="mb-5 text-white fs-2">
              Get in touch with us to find answers to your queries.
            </div>

            <div className="row my-5">
              <div className="col-12 col-md-6 mb-4">
                <form>
                  <div className="row">
                    <div className="col-6 mb-4">
                      <input
                        type="text"
                        placeholder="Your Name"
                        className="form-control w-100"
                        name=""
                        id=""
                      />
                    </div>
                    <div className="col-6 mb-4">
                      <input
                        type="text"
                        placeholder="Your Email"
                        className="form-control"
                        name=""
                        id=""
                      />
                    </div>
                    <div className="col-12 mb-4">
                      <input
                        type="text"
                        placeholder="Subject"
                        className="form-control"
                        name=""
                        id=""
                      />
                    </div>
                    <div className="col-12 col-md-12">
                      <textarea
                        name=""
                        className="form-control"
                        placeholder="Message"
                        id=""
                        cols="15"
                        rows="5"
                      ></textarea>
                    </div>
                    <div className="col-12 mb-4">
                      <LoadCanvasTemplate />
                    </div>
                    <div className="col-12 mb-4">
                      <input
                        placeholder="Enter Captcha Value"
                        id="user_captcha_input"
                        className="form-control"
                        name="user_captcha_input"
                        type="text"
                      />
                    </div>
                    <div className="col-12 col-md-12 mt-4">
                      <button
                        type="button"
                        onClick={() => {
                          let user_captcha_value =
                            document.getElementById('user_captcha_input').value

                          if (validateCaptcha(user_captcha_value) === true) {
                            console.log('Captcha Matched')
                          } else {
                            alert('Captcha Does Not Match')
                          }
                        }}
                        className="btn btn-primary submit-btn rounded-4 rounded-pill px-5"
                      >
                        Send Message
                      </button>
                    </div>
                  </div>
                </form>
              </div>

              {/* <div className="col-12 col-md-6 mb-3 address d-flex flex-column flex-md-row justify-content-center align-items-center rounded-4">
                <div>
                  <img src="./assets/image/location.svg" alt="sovrun location" />
                </div>
                <p className="text-white text-center text-md-start">
                  1962 Main Street, Sarasota, FL USA <br />
                  <br />
                  +65 22 333 22 00
                </p>
              </div> */}
            </div>
          </div>
        </div>
      </footer>
      <Footer></Footer>
    </>
  )
}

export default Contact
